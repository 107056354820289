import trackInMatomoAndGA from 'Shared/Analytics/CombinedGAMatomo';

onDomReady(function () {
   HeaderLanguages.initialize();
});

// eslint-disable-next-line no-var
export var HeaderLanguages = (window.HeaderLanguages = {
   initialize: function () {
      this.languageMenuToggle = $('languageMenuToggle');
      this.languageMenuContainer = $('languageMenuContainer');
      this.languageMenu = $('languageMenu');

      when(
         this.languageMenuToggle,
         function (el) {
            Header.setupHeaderDropdown(this.languageMenuToggle, this.languageMenuContainer);
         }.bind(this)
      );

      $$('.language-option').addEvent('click', function (ev) {
         let machineTranslate = this.hasClass('machine-translate');

         let form = this.getParent('form');
         let langidInput = form.getChildren('.langidInput');

         if (!machineTranslate && this.get('data-langid')) {
            langidInput.set('value', this.get('data-langid'));
         }

         form.submit();

         if (!App.canIBypassGAEventFiringOnMobile && App.lang != this.get('data-langid')) {
            trackInMatomoAndGA({
               eventCategory: 'Language Switcher',
               eventAction: 'Switch language',
            });
         }

         if (!machineTranslate) {
            ev.stop();
         }
      });
   },
});
