export { GlobalHeader };
window.GlobalHeader = GlobalHeader;
function GlobalHeader() {
   this.headerDropdownCallbacks = {};

   this.toggleHeaderDropdown = function (dropdownContainer, forceShow) {
      let dropdown = dropdownContainer.getElement('.header-dropdown');
      let isOpen = dropdownContainer && dropdownContainer.get('data-dropdown-header-open') === '';

      if (forceShow !== undefined && forceShow === isOpen) {
         return;
      }

      TweenMax.killTweensOf(dropdown);

      if (isOpen) {
         dropdownContainer.set('data-dropdown-header-open', null);
         TweenMax.to(dropdown, 0.8, {
            y: '-100%',
            ease: Circ.easeOut,
            onComplete: function () {
               dropdownContainer.removeClass('dropdown-container-active');
            },
         });
      } else {
         this.closeOpenHeaderDropdowns();
         dropdownContainer.set('data-dropdown-header-open', '');
         dropdownContainer.addClass('dropdown-container-active');
         TweenMax.to(dropdown, 0.6, {
            y: '0%',
            ease: Elastic.easeOut.config(1, 1),
         });
      }

      let callback = this.headerDropdownCallbacks[dropdownContainer.get('id')];
      if (callback) {
         callback(!isOpen);
      }
   };

   this.setupHeaderDropdown = function (toggleButton, dropdown, options) {
      if (!options) {
         options = {};
      }

      // Toggle the dropdown if the toggle button is clicked. Stop the process
      // if an 'unlock' boolean function is passed in and returns false.
      toggleButton.addEvent(
         'click',
         function (ev) {
            if (!(options.unlock && !options.unlock())) {
               options.onFirstOpen && options.onFirstOpen();
               delete options.onFirstOpen;
               ev.preventDefault();
               ev.stopPropagation();
               this.toggleHeaderDropdown(dropdown);
            }
         }.bind(this)
      );

      // Close the dropdown when clicking outside of it.
      window.addEvent(
         'click',
         function (ev) {
            if (!(dropdown.contains(ev.target) || toggleButton.contains(ev.target))) {
               this.toggleHeaderDropdown(dropdown, false);
            }
         }.bind(this)
      );
   };

   this.closeOpenHeaderDropdowns = function () {
      $$('[data-dropdown-header-open]').each(
         function (dropdown) {
            this.toggleHeaderDropdown(dropdown, false);
         }.bind(this)
      );
   };

   this.showLoggedIn = function (info) {
      when($('loggedInNav'), function (loggedInNav) {
         let firstName = info.username.replace(/\s+.*$/, '');
         $('menuUsername').set('text', firstName);
         $('loggedOutNav').hide();
         loggedInNav.show();
      });
   };

   this.showLoggedOut = function () {
      when($('loggedInNav'), function (loggedInNav) {
         loggedInNav.hide();
         $('loggedOutNav').show();
      });
   };
}
