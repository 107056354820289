onDomReady(function () {
   when($('js-iso-language-dropdown-button'), function (button) {
      window.languageDropdownButton.setProps({
         translationPreferencesUrl: App.translationPreferencesUrl,
         languages: App.languages,
         machineTranslationEnabled: App.machineTranslationEnabled,
         machineTranslationRequested: App.machineTranslationRequested,
      });
      window.languageDropdownButton.setRenderTarget($('js-iso-lang-dropdown'));

      window.languageDropdownButton.renderDropdown();
      button.addEvent('click', window.languageDropdownButton.toggle);
   });
});
