import { tween } from 'shifty/dist/shifty.core';
import trackInMatomoAndGA from 'Shared/Analytics/CombinedGAMatomo';

const handleSlideDrawers = () => {
   const toggles = document.querySelectorAll('.js-slide-toggle');
   const onClick = ev => {
      let el = ev.currentTarget;
      let dropdown = el.getNext('.js-slide-drawer');

      const previous = dropdown.style.height;
      dropdown.style.height = 'auto';
      const autoHeight = dropdown.getHeight();
      dropdown.style.height = previous;

      const tweenSettings = {
         render: ({ height }) => {
            dropdown.style.height = `${height}px`;
         },
         duration: 200,
         easing: 'easeInOutQuad',
      };

      if (window.innerWidth < 600) {
         if (dropdown.getHeight() == 0) {
            tween({
               ...tweenSettings,
               from: { height: 0 },
               to: { height: autoHeight },
            }).then(() => el.addClass('drawer-open'));
         } else {
            tween({
               ...tweenSettings,
               from: { height: dropdown.getHeight() },
               to: { height: 0 },
            }).then(() => el.removeClass('drawer-open'));
         }
      }
   };

   for (let i = 0; i < toggles.length; i++) {
      toggles[i].addEventListener('click', onClick);
   }
};

onDomReady(() => {
   handleSlideDrawers();
});
